<template>
    <div class="footer-container">
        <div class="footer-left">
            <div class="lists">
                <div class="address">
<!--                    <span>原链科技有限公司</span>-->
                    <img class="logo" src="@/assets/logo@2x.png"/>
                    <div class="text">公司地址：成都市武侯区联邦财富中心A座1单元702</div>
                    <div class="contact-phone">
                        <span>业务咨询:</span>
                        <span>&nbsp;&nbsp;18682751018</span>
                    </div>
                    <div class="consultation-hours">咨询时间：周一至周五9：00-18：00</div>
                </div>
                <div class="tab" v-for="(item,i) in tabs" :key="i">
                    <span class="tab-name" @click="clickNav(item.key)">{{item.tabName}}</span>
                    <ul class="list" v-for="(jack,c) in item.children" :key="c" v-if="c<4">
                        <li class="link" @click="clickChildren(jack)">{{jack.name}}</li>
                    </ul>
                </div>
            </div>
                <div class="property" @click="go">{{property}}</div>
        </div>
        <div class="footer-right">
<!--            <div class="contact-phone">-->
<!--                <span>业务咨询:</span>-->
<!--                <span>158125236266</span>-->
<!--            </div>-->
<!--            <div class="consultation-hours">咨询时间：周一至周五9：30-18：00</div>-->
            <div class="QR-code-container">
                <div class="QR-code">
                    <img src="@/assets/QR-codePersonal.png"/>
                    <span>企业联系人</span>
                </div>
                <div class="QR-code">
                    <img src="@/assets/QR-code.png"/>
                    <span>企业公众号</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import handleRequest from "@/utils/handleRequest";
import {selectProduct} from "@/api/apis";

export default {
    name: "Y-footer",
    data() {
        return {
            property:'成都原链科技有限公司|蜀ICP备18005934号-4',
            tabs: [
                {
                    tabName:'首页',
                    key:'index',
                    children:[]
                },
                {
                    tabName:'产品服务',
                    key:'product',
                    children:[]
                },
                {
                    tabName:'解决方案',
                    key:'solve',
                    children:[]
                },
                {
                    tabName:'项目案例',
                    key:'case',
                    children:[]
                },
                {
                    tabName:'最新动态',
                    key:'news',
                    children:[]
                },
                {
                    tabName:'关于我们',
                    key:'about',
                    children:[]
                },
            ]
        }
    },
    mounted() {
        this.getProductDatactData();
    },
    methods: {
        go(){
            window.open('https://beian.miit.gov.cn/#/Integrated/recordQuery')
        },
        async getProductDatactData() {
            const data = handleRequest(await selectProduct({})).content
            data.forEach(item=>{
                item.introduce = JSON.parse(item.introduce)
                item.scenario = JSON.parse(item.scenario)
                if(item.coverImg){
                    item.coverImg = JSON.parse(item.coverImg)
                }
            })
            this.tabs[1].children = data
        },
        jump(path){
            this.$router.push(path)
        },
        clickChildren(children){
            if(this.$route.path !== '/product'){
                this.$router.push('/product')
                this.$store.commit('setSelected',['product'])
            }else{
                this.$store.commit('setFooterSetCurProductId',children.id)
            }
        },
        clickNav(key) {
            console.log(key)
            const {jump} = this
            switch (key){
                case 'index':
                    jump('/')
                    break;
                case 'product':
                    jump('/product')
                    break;
                case 'solve':
                    jump('/solve')
                    break;
                case 'case':
                    jump('/case')
                    break;
                case 'news':
                    jump('/news')
                    break;
                case 'about':
                    jump('/about')
                    break;
            }
        }

    },

}
</script>
<style scoped lang="scss">
.footer-container{
    background: linear-gradient(90deg, #00C8FF 0%, #3769FE 100%);
    padding: 35px 40px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    .footer-left{
        user-select: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
       .lists{
           display: flex;
           justify-content: space-between;
           .address{
               font-weight: 550;
               .logo{
                   width: 165px;
                   height: 38px;
                   margin-left: 10px;
               }
               div{
                   padding: 13px 0;
                   font-size: 16px;
               }
           }
           .tab{
               width: 150px;
               display: flex;
               flex-direction: column;
               align-items: center;
               text-align: center;
               .tab-name{
                   width: 80px;
                   font-size: 18px;
                   margin-bottom: 10px;
                   cursor: pointer;
                   &:hover{
                       font-weight: 550;
                   }
               }
               .list{
                   padding: 0;
                   margin: 0;
                   padding: 10px 0;
                   list-style: none;
                   .link{
                       cursor: pointer;
                       &:hover{
                           font-weight: 550;
                       }
                   }
               }
           }
       }
        .property{
            text-align: left;
            font-size: 18px;
            font-weight: 550;
            cursor: pointer;
        }

    }
    .footer-right{
        div{
            margin-bottom: 20px;
        }
        .contact-phone{
            :nth-child(2){
                font-size: 32px;
                margin-left: 10px;
            }
        }
        .consultation-hours{

        }
        .QR-code-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            //margin-top: 30px;
            margin-bottom: 0;
            margin-left: 130px;
            .QR-code{
                margin-bottom: 0;
                span{
                    padding: 10px 0;
                    display: block;
                    text-align: center;
                }
                img{
                    width: 80px;
                }
            }
        }
    }
}
</style>
