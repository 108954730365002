<template>
    <div class="container">
        <Y-header class="header" font-color="#FFFFFF" search-color="aliceblue"></Y-header>
        <div class="body-container">

            <banner class="banner" :data="bannerData" height="600px" :speed="bannerSpeed"></banner>

           <div class="bgw pd-40">
               <center-title text="持续创新的产品和服务" bold font-size="42" position="center"></center-title>
               <div class="product-info Waistline">
                   <center-title  text="围绕企业定制开发服务的数字化管理和设备互联，构建智慧产品服务" font-size="20" position="center" color="#808080"></center-title>
                   <div class="tab-box">
                       <div class="tab-item" :class="[index == i ? 'tab-item-active':'']" v-for="(item,i) in listData" :key="i" @click="handleTabChange(item,i)" v-if="i<8">
                           <span class="title">{{item.name}}</span>
                           <div class="label-box">
                               <span class="label" v-for="(jack,d) in item.introduce" :key="d">{{jack.characteristic }}</span>
                           </div>
                       </div>
                   </div>
               </div>
               <div class="product-tab-item Waistline">
                   <div class="tab-left">
                       <h1 class="title">{{currentProduct.name}}</h1>
                       <p class="content" v-if="textShow">{{currentProduct.introduce[0].introduce}}</p>
                       <div class="labels">
                           <span class="label" v-for="(item,i) in currentProduct.scenario" :key="i" v-if="i<4">{{item.name}}</span>
                       </div>
                       <a-button class="check-button" @click="goPorduct">了解详情</a-button>
                   </div>
                   <div class="tab-right" v-if="textShow">
                       <img class="ue" v-if="currentProduct.scenario[0]?.img?.length" :src="currentProduct.scenario[0]?.img[0]?.url"/>
                       <img class="under" v-if="currentProduct.scenario[0]?.img?.length" :src="currentProduct.scenario[0]?.img[1]?.url||currentProduct.scenario[0]?.img[0]?.url"/>
                   </div>
               </div>
           </div>

            <center-title text="客户的信任和案例" bold font-size="42" position="center"></center-title>
            <div class="Waistline">
                <center-title  text="来自各地客户的信任，已服务超过100+的付费企业" font-size="20" position="center" color="#808080"></center-title>
                <a-list :grid="{ gutter: 30, column: 6 }" :data-source="caseData">
                    <a-list-item slot="renderItem" slot-scope="item, index">
                        <div class="case-item">
                            <div class="before">
                                <img :src="item.src"/>
                            </div>
                            <div class="behind" @click="handleBehindClick(item)">
                                <span>{{item.name}}</span>
                            </div>
                        </div>
                    </a-list-item>
                </a-list>
            </div>


            <div class="news-box bgw">
                <center-title text="行业最新资讯" bold font-size="42" position="center"></center-title>
                <center-title  text="围绕企业定制开发服务的数字化管理和设备互联，构建智慧产品服务" font-size="20" position="center" color="#808080"></center-title>
                <div class="news-container Waistline" ref="scrollBox">
                    <news-card  v-for="(item,i) in newsData" :key="i" :data-source="item" :keys="['name','createTime','src','content']" @click="jumpNews(item)"></news-card>
                </div>
            </div>

        </div>
        <Y-footer></Y-footer>
        <a-back-top/>

    </div>
</template>

<script>
import banner from "@/components/banner.vue";
import centerTitle from "@/components/centerTitle.vue";
import newsCard from "@/components/newsCard.vue";
import footer from "@/components/footer.vue";
import header from "@/components/header.vue";
import {bannerData, selectNews, selectProduct} from "@/api/apis";
import handleRequest from "@/utils/handleRequest";
export default {
    name: 'Home',
    components: {
        'Y-footer':footer,
        'Y-header':header,
        banner,
        centerTitle,
        newsCard,
    },
    data() {
        return {
            listData:[],
            listDataLoading:false,
            bannerData:[],
            bannerIntroduce:[],
            caseData:[],
            newsData:[],
            bannerSpeed:2000,
            currentProduct:{},
            textShow:false,
            index:0,
        }
    },
    created(){
        sessionStorage.setItem('tabSelected','index')
    },
    mounted() {
        this.getListData();
        this.getBannerData();
        this.getCaseData();
        this.getNewsData();
        this.makeScroll();
        this.autoDisplay()
    },
    methods:{
        //自动轮播产品服务
        autoDisplay(){
            let flag = true
            let timer = setInterval(()=>{
                if(flag){
                    this.index = this.listData.findIndex(item=>item.id === this.currentProduct.id)
                    if(this.index === this.listData.length-1){
                        this.index = 0
                    }else{
                        this.index += 1
                    }
                    this.currentProduct = this.listData[this.index]
                }
            },3000)

        },
        goPorduct(){
            console.log(this.currentProduct)
            this.$router.push({
                path:'/product',
                query:{id:this.currentProduct.id}
            })
            this.$store.commit('setSelected',['product'])
        },
        clearRichText(texts){
            let text1 = texts.replace(/<\/?(img|table)[^>]*>/g,"")
            let text2 = text1.replace(/<\/?.+?>/g,"")
            let text3 = text2.replace(/[ | ]*\n/g,"")
            let text4 = text3.replace(/ /g,"")
            return text4
        },
        makeScroll(){
            let flag = false
            let scrollLeft
            let downX
            this.$refs['scrollBox'].addEventListener('mousedown',(e)=>{
                flag = true
                downX = e.clientX
                scrollLeft = this.$refs['scrollBox'].scrollLeft
                this.$refs['scrollBox'].addEventListener('mousemove',(event)=>{
                    if(flag) {
                        let moveX = event.clientX
                        let scrollX = moveX - downX
                        if(scrollX < 0 && scrollLeft > 0){
                            this.$refs.scrollBox.scrollLeft = scrollLeft - scrollX
                        }else{
                            this.$refs.scrollBox.scrollLeft = scrollLeft - scrollX
                        }

                    }
                })
                this.$refs['scrollBox'].addEventListener('mouseup',()=>{
                    flag = false
                })
            })
        },
        handleTabChange(item,i){
            this.currentProduct = item
            console.log(this.currentProduct)
        },
        jumpNews(item){
            console.log(item)
            this.$router.push({
                path:'/newsDetail',
                query:{id:item.id}
            })
        },
        handleBehindClick(item){
            console.log('点击了卡片背后',item.name)
        },
        async getListData(){
            this.listDataLoading = true
            const res = handleRequest(await selectProduct({})).content
            res.forEach(item=>{
                item.introduce = JSON.parse(item.introduce)
                item.scenario = JSON.parse(item.scenario)
            })
            this.listData = res
            this.currentProduct = this.listData[0]
            if(this.currentProduct.introduce && this.currentProduct.scenario){
                this.textShow = true
            }
            this.listDataLoading = false
        },
        async getBannerData(){
            const data = handleRequest(await bannerData({type:'首页',state:1}))
            let banner = data.content
            banner.forEach(item=>{
                item.img = JSON.parse(item.img)[0]
            })
            this.bannerSpeed = (banner[0]?.second*1000) || this.bannerSpeed
            this.bannerData = banner
        },
        getCaseData(){
            setTimeout(()=>{
                const data = [
                    {
                        name:'新希望六和',
                        src:require('@/assets/companyLogo/新希望六和.png'),
                    },
                    {
                        name:'新绿色药业',
                        src:require('@/assets/companyLogo/新绿色药业.png'),
                    },
                    {
                        name:'同方鼎欣',
                        src:require('@/assets/companyLogo/同方鼎欣.png'),
                    },
                    {
                        name:'西昌市农业农村局',
                        src:require('@/assets/companyLogo/西昌市农业农村局.png'),
                    },
                    {
                        name:'镇巴县科技进步促进中心',
                        src:require('@/assets/companyLogo/镇巴县科技进步促进中心.png'),
                    },
                    {
                        name:'四川省中医科学院',
                        src:require('@/assets/companyLogo/四川省中医科学院.png'),
                    },
                    {
                        name:'阿鲁科尔沁旗扶贫办',
                        src:require('@/assets/companyLogo/阿鲁科尔沁旗扶贫办.png'),
                    },
                    {
                        name:"陕西省商务厅",
                        src:require('@/assets/companyLogo/陕西省商务厅.png'),
                    },
                    {
                        name:"晋城市商务局",
                        src:require('@/assets/companyLogo/晋城市商务局.png'),
                    },
                    {
                        name:'阿鲁科尔沁旗经济和信息化局',
                        src:require('@/assets/companyLogo/阿鲁科尔沁旗经济和信息化局.png'),
                    },
                    {
                        name:"爸爸的果园",
                        src:require('@/assets/companyLogo/爸爸的果园.png'),
                    },
                    {
                        name:"长武县果业中心",
                        src:require('@/assets/companyLogo/长武县果业中心.png'),
                    },

                ]
                this.caseData = data
            },1000)
        },
        async getNewsData(){
           const res = handleRequest(await selectNews({})).content
           this.newsData = res
           this.newsData.forEach(item=>{
               item.coverImg = JSON.parse(item.coverImg)
               item.src = item.coverImg[0].url
               item.content = this.clearRichText(item.content)
           })

        },
    }
}
</script>
<style lang="scss" scoped>
.v-enter{
    opacity: 0;
    transition: all 0.3s;
}
.v-enter-to{
    opacity:1;
    transition: all 0.3s;
}
@media screen and (max-width: 1768px){
    .tab-box{
        transition: all 0.3s;
        display: flex;
        box-shadow: 0 0 10px 5px #d7d7d7;
        border-radius: 10px;
        overflow: hidden;
        background-color: #FFFFFF;
        .tab-item-active{
            background-color: #3769FE;
            color: #FFFFFF;
        }
        .tab-item{
            width: 14.2rem;
            height: 173px !important;
            padding: 20px;
            border-right: 1px solid #d7d7d7;
            &:hover{
                background-color: #3769FE;
                color: #FFFFFF;
            }
            .title{
                font-size: 1rem;
                font-weight: bold;
            }
            .label-box{
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                .label{
                    margin-right: 5px;
                }
            }
        }
    }
    .product-tab-item{
        min-height:550px;
        padding:20px;
        display: flex;
        flex-direction: column;
        .tab-left{
            margin-bottom: 20px;
            padding-right: 0 !important;
            width:100% !important;
            .check-button{
                margin-top: 10px !important;
                width: 200px;
                height: 56px;
                background: linear-gradient(90deg, #00C8FF 0%, #3769FE 100%);
                border-radius: 28px;
                color: #FFFFFF;
                font-size: 18px;
            }
            .content{
                font-size: 16px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #505050;
                line-height: 34px;
            }
            .labels{
                margin: 40px 0;
                display: flex;
                //justify-content: space-between;
                .label{
                    margin-right:60px;
                    font-size: 20px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 20px;
                }
            }

        }
        .tab-right{
            width: 100% !important;
            position: relative;
            .under{
                width:500px;
                height: 310px;
                z-index: 2;
                filter: blur(2px);
            }
            .ue{
                width:500px;
                height: 310px;
                top: 50px;
                right: 30px;
                position: absolute;
                z-index: 1;
            }
        }
    }
    .news-container{
        user-select: none;
        &::-webkit-scrollbar{
            height: 0;
        }
        width:auto !important;
        overflow-y: hidden;
        background-color: #FFFFFF;
        display: flex;
        //justify-content: space-between;

    }
}
.bgw{
    background-color: #FFFFFF;
}
.pd-40{
    padding: 40px 0;
}
.Waistline{
    margin: 0 160px;
}
.container{
    .body-container{
        display: block;
        //margin:0 160px;
        .banner{
            position: relative;
        }
        .product-info{
            //just a flag
            height: 260px;
            background-image: url("../assets/代码图@2x.png");
            background-repeat: repeat;
            background-size: 100%;
            background-position-y: 280px;
            .tab-box{
                transition: all 0.3s;
                display: flex;
                box-shadow: 0 0 10px 5px #d7d7d7;
                border-radius: 10px;
                overflow: hidden;
                background-color: #FFFFFF;
                .tab-item-active{
                    background-color: #3769FE;
                    color: #FFFFFF;
                }
                .tab-item{
                    width: 14.2rem;
                    height: 126px;
                    padding: 20px;
                    border-right: 1px solid #d7d7d7;
                    &:hover{
                        background-color: #3769FE;
                        color: #FFFFFF;
                    }
                    .title{
                        font-size: 1rem;
                        font-weight: bold;
                    }
                    .label-box{
                        margin-top: 10px;
                        .label{
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
        .product-tab-item{
            min-height:550px;
            padding:20px;
            display: flex;
            .tab-left{
                padding-right: 100px;
                width:70%;
                .check-button{
                    margin-top: 60px;
                    width: 200px;
                    height: 56px;
                    background: linear-gradient(90deg, #00C8FF 0%, #3769FE 100%);
                    border-radius: 28px;
                    color: #FFFFFF;
                    font-size: 18px;
                }
               .content{
                   font-size: 16px;
                   font-family: PingFangSC, PingFang SC;
                   font-weight: 400;
                   color: #505050;
                   line-height: 34px;
               }
                .labels{
                    margin: 40px 0;
                    display: flex;
                    //justify-content: space-between;
                    .label{
                        margin-right:60px;
                        font-size: 20px;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        line-height: 20px;
                    }
                }

            }
            .tab-right{
                width: 30%;
                position: relative;
                .under{
                    width:500px;
                    height: 310px;
                    z-index: 2;
                    filter: blur(2px);
                }
                .ue{
                    width:500px;
                    height: 310px;
                    top: 50px;
                    right: 30px;
                    position: absolute;
                    z-index: 1;
                }
            }
        }

        .case-item{
            background-color: #FFFFFF;
            border-radius: 12px;
            $height:120px;
            height: $height;
            box-shadow: 0 0 4px 1px #d2d2d2ff;
            transition: all 1s;
            overflow: hidden;
            position: relative;
            &:hover{
                transform: rotate3d(0,1,0,180deg);
                img{
                    visibility: hidden;
                }
                .behind{
                    visibility: unset;
                }
            }
            img{
                height: $height;
            }
            .before{
                width: 100%;
                height: 100%;
                position: absolute;
                display: flex;
                justify-content: space-around;
                align-items: center;
            }
            .behind{
                width: 100%;
                height: 100%;
                position: absolute;
                display: flex;
                justify-content: space-around;
                align-items: center;
                font-size: 20px;
                visibility: hidden;
                transform: rotateY(180deg) !important;
            }
        }

        .news-box{
            padding: 40px 0 0 0;
        }
        .news-container{
            user-select: none;
            &::-webkit-scrollbar{
                height: 0;
            }
            width:1500px;
            overflow-y: hidden;
            background-color: #FFFFFF;
            display: flex;
            //justify-content: space-between;

        }
    }
}
</style>
