export function dateFormat(t, format) {
    let time = new Date()
    if (t) {
        time = new Date(t)
    }
    let y = time.getFullYear()
    let m = time.getMonth() < 9 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1
    let d = time.getDate() < 10 ? '0' + time.getDate() : time.getDate()
    let h = time.getHours() < 10 ? '0' + time.getHours() : time.getHours()
    let M = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()
    let s = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds()
    let arr = new Array("日", "一", "二", "三", "四", "五", "六");
    let w = arr[time.getDay()]
    if (format == 'y-m-d h:M:s') return `${y}-${m}-${d} ${h}:${M}:${s}`
    if (format == 'h:M:s') return `${h}:${M}:${s}`
    if (format == 'y-m-d') return `${y}-${m}-${d}`
    if (format == 'm-d') return `${m}-${d}`
    if (format == 'y-m') return `${y}-${m}`
    if (format == 'y') return `${y}`
    if (format == 'w') return `星期${w}`
}
