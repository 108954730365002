<template>
    <div class="text-container" :style="{textAlign:position}">
        <span :style="{fontSize:fontSize+'px',fontWeight:bold?bold:'bold',color:color}">{{text}}</span>
    </div>
</template>

<script>
export default {
    name: "centerTitle",
    props:['text','fontSize','bold','position','color'],
    computed:{
    },
    methods:{
    }
}
</script>

<style scoped lang="scss">
.text-container{
    margin: auto;
    padding: 20px;
    span{
    }
}
</style>
