import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    toPhone:false,
    tabSelected:['index'],
    menuSelected:['banner'],
    productShow:false,
    footerSetCurProductId:'',
  },
  mutations: {
    setToPhone(state,payload){
      state.toPhone = payload
    },
    setFooterSetCurProductId(state,payLoad){
      state.footerSetCurProductId = payLoad
    },
    setProductShow(state,payLoad){
      state.productShow = payLoad
    },
    setSelected(state,payload){
      state.tabSelected = payload
      sessionStorage.setItem('tabSelected',payload)
    },
    setSelectedMain(state,payload){
      state.menuSelected = payload
      sessionStorage.setItem('tabSelectedMain',payload)
    }
  },
  actions: {
  },
  modules: {
  }
})
