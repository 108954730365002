import {postFormData ,post,get} from "./request";

/** @description 上传接口
 *  @paramter - { formData }
 * **/
export const fileUpload = (params)=>postFormData('/file/upload/',params)

/** @description 查询banner应用模块的分组
 *  @paramter 无参数
 * **/
export const bannerApplyModule = (query) => get('/yenlien/findBannerType',query)

/** @description 查询产品服务下的内容
 *  @paramter 无参数
 * **/
export const productType = (query)=>get('/yenlien/findProdType',query)

/** @description 查询所有新闻资讯
 *  @param params - {
 *  @param name:string 条件查询新闻名称
 *  @param homeShow:string 条件查询首页展示
 *  @param publisher:string 条件查询发布人
 *  @param state:string|number -1 已发布 -2待发布
 *
 *  }
 * **/
export const newsRequest = (params) =>post('/yenlien/findNewsList',params)

/** @description 新增/保存banner
 *  @param params - {
 *  @param sort:string|number 序号
 *  @param type:string 应用至页面
 *  @param model：string|number 跳转模式 1=站内, 2=站外, 3=无跳转
 *  @param name:string 轮播图分组名称
 *  @param prodType:string 产品服务下的子类名称
 *  @param second:string|number 轮播图持续时间 秒/s
 *  @param target:string 跳转地址
 *  @param img:string[] JSON 上传的图片JSON数组
 *
 *  }
 *  @remark 携带id为修改
 * **/
export const saveOrEditBanner = (params)=>post('/yenlien/saveBanner',params)

/** @description 分页查询banner
 *  @param  type 类型 string
 *  @param  state 是否启用 -1 -2 string
 *  @param  page 当前页
 *  @param  pageSize 查询页数据个数
 *  @remark 查询所有传空对象即可
 * **/
export const bannerData = (params)=>post('/yenlien/findBannerPage',params)

/** @description 根据id删除banner
 *  @param id string 必传
 * **/
export const deleteBanner = (query)=>get('/yenlien/delBannerById',query)

/** @description 新增/修改项目案例
 *  @param params - {
 *  @param sort:string|number 序号,
 *  @param name:string 名称
 *  @param introduce:string 富文本内容
 *  @param hardware:string[] JSON 硬件
 *  @param software:string[] JSON 软件
 *  @param state:string|number 启用状态
 *
 *  }
 * **/
export const saveOrEditProjectCase = (params)=>post('/yenlien/saveProjectExample',params)

/** @description 分页查询选项目案例
 *  @param name：string 项目名称
 *  @param state：string|number 是否启用 -1 启用 -2 禁用
 *  @param  page 当前页
 *  @param  pageSize 查询页数据个数
 *  @remark 不传参默认查询所有
 * **/
export const projectCase = (params)=>post('/yenlien/findProjectExamplePage',params)

/** @description 根据id删除项目案例
 *  @param id string
 * **/
export const deleteProjectCase = (query)=>get('/yenlien/delProjectExampleById',query)

/** @desciption 新增/修改产品服务
 *  @param params - {
 *  @param sort string|number 序号
 *  @param name string 产品服务名称
 *  @param introduce Array<{characteristic:string,introduce:string}> = [] JSON格式 产品介绍
 *
 *  }
 * **/
export const saveOrEditProduct = (params)=>post('/yenlien/saveProduct',params)

/** @description 分页查询产品服务
 *  @param name string 产品服务名称
 *  @param homeShow boolean 首页展示
 *  @param state string|number 是否启用
 *  @param page string|number 查询页数
 *  @param pageSize string|number 当前页查询个数
 * **/
export const selectProduct = (params) =>post('/yenlien/findProductPage',params)

/** @description 删除产品服务
 *  @param id string 产品服务id
 * **/
export const deleteProduct = (query) =>get('/yenlien/delProductById',query)

/** @description 保存/修改新闻资讯
 *  @param params - {
 *  @param sort string|number 序号
 *  @param id string 资讯id 修改或删除时使用
 *  @param name string 文章名称
 *  @param content string 文章富文本内容
 *  @param publisher string 发布人
 *  @param coverImg string 文章封面
 *  @param homeShow Boolean 是否首页展示
 *
 *  }
 * **/
export const saveOrEditNews = (params) =>post('/yenlien/saveNews',params)

/** @description 分页查询新闻资讯
 *  @param name string 新闻资讯名称
 *  @param homeShow boolean 首页展示
 *  @param state string|number 是否发布
 *  @param page string|number 查询页数
 *  @param pageSize string|number 当前页查询个数
 * **/
export const selectNews = (params) =>post('/yenlien/findNewsPage',params)

/** @description 删除新闻资讯
 *  @param id string 新闻资讯id
 * **/
export const deleteNews = (query) =>get('/yenlien/delNewsById',query)

/** @description 保存/修改解决方案
 *  @param params - {
 *  @param sort string|number 序号
 *  @param id string 解决方案id 修改或删除时使用
 *  @param name string 行业名称
 *  @param scenario Array<{painPoint:string}> = [] JSON格式 行业痛点
 *  @param planIntroduce string 方案描述
 *  @param planImg string 方案图片多个图片使用;分割
 *  @param execute string 实施内容
 *  @param executeImg string 实施图片多个图片使用;分割
 *  @param application string 应用场景
 *  @param applicationImg string 应用图片多个图片使用;分割
 *
 *  }
 * **/
export const saveOrEditSolution = (params) =>post('/yenlien/saveSolution',params)

/** @description 分页查询解决方案
 *  @param name string 行业名称
 *  @param planName string 解决方案名称
 *  @param state string|number 是否发布
 *  @param page string|number 查询页数
 *  @param pageSize string|number 当前页查询个数
 * **/
export const selectSolution = (params) =>post('/yenlien/findSolutionPage',params)

/** @description 删除解决方案
 *  @param id string 解决方案id
 * **/
export const deleteSolution = (query) =>get('/yenlien/delSolutionById',query)

/** @description 保存/修改关于我们
 *  @param params - {
 *  @param introduce string 企业描述
 *  @param img string 企业图片多个图片使用;分割
 *  @param license string 资质图片多个使用;分割
 *  @param email string 邮箱
 *  @param phone string|number 电话
 *  @param address string 公司地址
 *
 *  }
 * **/
export const saveOrEditAbout = (params) =>post('/yenlien/saveInfo',params)

/** @description 查询关于我们 **/
export const selectAboutData = (query) =>get('/yenlien/findYenlien',query)

/** @description 验证码接口 **/
export const checkCode = (query)=>get('/user/checkCode',query)
