import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue/es'
import 'ant-design-vue/dist/antd.css';
import VuePrlx from 'vue-prlx'
import { VuePrlxDirective } from 'vue-prlx'
import baseURL from "@/utils/config";
import {dateFormat} from '@/utils/timeHandler'
import vueSeamlessScroll from "vue-seamless-scroll"
import VueParticles from "vue-particles";
Vue.component('vue-seamless-scroll',vueSeamlessScroll)
Vue.prototype.$api = baseURL
Vue.prototype.dateFormat = dateFormat
Vue.config.productionTip = false
Vue.use(Antd)
Vue.use(VuePrlx);
Vue.use(VueParticles)
Vue.directive('prlx', VuePrlxDirective);

if(process.env.NODE_ENV === 'production'){
  if(window){
    window.console.log = function (){}
  }
}

new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
