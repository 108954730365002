import {message} from "ant-design-vue";

/** @description 解决繁琐的code判断
 *  @param res 请求到的响应
 *  @param callback 处理后续逻辑 -> 返回值是请求的响应
 *  @remark const data = handleRequest()
 *  @remark 如果需要code判断显示提示，传入回调处理或者自行处理
 * **/
export default function (res,callback){
    try {
        if(res?.code == 200){
            if(callback){
                callback(res)
            }else{
                return res.data
            }
        }else{
            message.error(res.msg)
        }
    }catch (e) {
        throw e
        message.error(e)
    }
}
