<template>
    <div class="newsCard-container">
        <a-skeleton active v-if="loading"/>
        <div v-if="!loading" style="padding: 10px">
            <div  class="new-item">
                <div class="item-left">
                    <p class="title" @click="emitEvent">{{data[keys[0]]}}</p>
                    <span class="content">{{data[keys[3]]}}</span>
                    <div class="time-box">
                        <span class="time">{{data[keys[1]]}}</span>
                    </div>
                </div>
                <div class="item-right" @click="emitEvent">
                    <img v-prlx="{  custom: true,limit: { min: 30, max: 120 } }" :src="data[keys[2]]"/>
                </div>
            </div>
<!--            <a-divider />-->
        </div>
    </div>
</template>
<script>
export default {
    name: "newsCard",
    props:['dataSource','keys'],
    data() {
        return {
            data:[],
            loading:false,
        }
    },
    mounted() {
        this.loading = true
        setTimeout(()=>{
            this.loading = false
        },1000)
        this.changeKey(this.dataSource,this.keys);
    },
    methods: {
        emitEvent(){
            this.$emit('click',this.dataSource)
        },
        changeKey () {
            try {
                if(this.dataSource){
                    let obj = this.dataSource || []
                    let temp = JSON.stringify(obj)
                    obj = JSON.parse(temp)
                    this.data = obj
                }
            }catch (e) {
                throw e
            }
        }
    },
}
</script>
<style scoped lang="scss">
@media screen and (max-width: 1900px) {
    .newsCard-container{
        width: 100%;
        .new-item{
            justify-content: space-between !important;
        }
    }
}
.new-item{
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding: 20px;
    .item-left{
        width: 290px;
        margin: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title{
            font-size: 18px;
            font-weight: bold;
            cursor: pointer;
        }
        .content{
            height: 60px;
            overflow: hidden;
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
        }
        .time-box{
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .icon{
                &:hover{
                    font-size: 14px;
                }
            }
        }
    }
    .item-right{
        img{
            cursor: pointer;
            -webkit-user-drag: none;
            object-fit: cover;
            border-radius: 23px;
            transform: scale(calc(var(--parallax-value) / 50 ));
            width: 300px;
            height: 220px;
            transition:all .5s ;
            &:hover{
                transform: scale(1.1);
                box-shadow: 0px 0px 10px 3px #8d8d8d;
            }
        }
    }
}
</style>
