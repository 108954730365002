<template>
    <div class="container">
        <div class="cover">
            <vue-particles
                class="bg"
                color="#409EFF"
                :particle0pacity="0.7"
                :particlesNumber="80"
                shapeType="circle"
                :particlesize="4"
                linesColor="#409EFF"
                :lineswidth="1"
                :lineLinked="true"
                :lineopacity="0.4"
                :linesDistance="150"
                :movespeed="3"
                :hoverEffect="true"
                hoverMode="grab"
                :clickEffect="true"
                clickMode="push"
                :style="{height:getWindowHeight}"
            >
            </vue-particles>
            <h1 class="not-found">404 not found</h1>
            <a-button class="back-btn" type="primary" @click="()=>{$router.go(-1)}">返回</a-button>
        </div>
        <div class="right">
            <img src="@/assets/logo@2x.png"/>
            <h1 class="text">lost page</h1>
        </div>
    </div>
</template>

<script>
import SIdentify from '../components/authcode'
import router from "@/router";

export default {
    name: "Page404",
    methods: {
        router() {
            return router
        }
    },
    components: { SIdentify },
    computed:{
        getWindowHeight(){
            return document.body.clientHeight+'px'
        },
    },

}
</script>
<style scoped lang="scss">
.container{
    width: 100%;
    height: 100vh;
    //background-image: url("https://img2.huashi6.com/images/resource/2018/11/26/7183707h1p0.jpg?imageMogr2/quality/100/interlace/1/thumbnail/2000x%3E");
    background-image: url("../assets/login-BG.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .cover{
        width: 100%;
        height: 100vh;
        background-color: rgba(255, 255, 255, 0.91);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        position: relative;
        .bg{
            position: fixed;
            z-index: 1;
        }
    }
    .back-btn{
        z-index: 10;
        border-radius: 20px;
        width: 160px;
        height: 40px;
        position: absolute;
        right:80px;
        bottom: 260px;
    }
    .not-found{
        color: #000000;
        font-size: 184px;
        font-weight: bolder;
        margin: 0;
        text-align: center;
    }
    .right{
        text-align: center;
        img{

        }
        .text{
            color: #ffffff;
            font-size: 184px;
            font-weight: bolder;
            margin: 0;
            text-align: center;
        }

    }
}
</style>
