import axios from "axios";
import $api from "@/utils/config";
if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = $api.baseURL()
} else if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = $api.baseURL()
}
axios.defaults.timeout = 20000
console.log('当前请求地址：',axios.defaults.baseURL)
export const get = (url,query) => {
    return new Promise((resolve,reject)=>{
        axios.get(url,{params:query}).then(res=>{
            resolve(res.data)
        }).catch(err=>{
            reject(err)
        })
    })
}

export const post = (url,params)=>{
    return new Promise((resolve, reject)=>{
        axios.post(url,params,{
            headers:{'Content-Type': 'application/json;charset=UTF-8'}
        }).then(res=>{
            resolve(res.data)
        }).catch(err=>{
            reject(err)
        })
    })
}

export const postFormData = (url,params)=>{
    return new Promise((resolve, reject) => {
        axios.post(url,params,{
            headers: {'Content-Type':'multipart/form-data'}
        }).then(res=>{
            resolve(res.data)
        }).catch(err=>{
            reject(err)
        })
    })
}

