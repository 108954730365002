<template>
    <div class="main-container" :style="{height:100+'%'}">
        <div class="sider">
            <div class="logo" @click="temp">
                <img v-if="!collapsed" src="@/assets/logo@2x.png"/>
<!--                <span v-if="!collapsed">原链科技有限公司</span>-->
            </div>
            <a-menu
                v-model="selected"
                mode="inline"
                style="height: 100%"
                theme="dark"
                :inline-collapsed="collapsed"
                @click="menuClick"
            >
                <a-menu-item key="banner">
                    <a-icon type="picture" />
                    <span>banner</span>
                </a-menu-item>
                <a-menu-item key="项目案例">
                    <a-icon type="project" />
                    <span>项目案例</span>
                </a-menu-item>
                <a-menu-item key="产品服务">
                    <a-icon type="coffee" />
                    <span>产品服务</span>
                </a-menu-item>
                <a-menu-item key="新闻资讯">
                    <a-icon type="read" />
                    <span>新闻资讯</span>
                </a-menu-item>
                <a-menu-item key="解决方案">
                    <a-icon type="reconciliation" />
                    <span>解决方案</span>
                </a-menu-item>
                <a-menu-item key="关于我们">
                    <a-icon type="smile" />
                    <span>关于我们</span>
                </a-menu-item>
            </a-menu>
        </div>
        <div class="right-side">
            <div class="header">
                <a-button class="shink" type="primary"  @click="toggleCollapsed">
                    <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
                </a-button>
                <div class="right-menu">
                <span>
                     <a-icon type="search" style="font-size: 18px;color: #888888"></a-icon>
                </span>
                    <span>
                     <a-icon type="bell" style="font-size: 18px;color: #888888"></a-icon>
                </span>
                    <a-avatar class="avatar" alt="原链" :size="32" icon="user" :src="avatar"/>
                    <a-popover title="">
                        <template slot="content">
                            <span class="logout-text" @click="logout">退出登录</span>
                        </template>
                        <span class="username" v-if="username">{{username}}</span>
                    </a-popover>
                </div>
            </div>
            <div class="view">
                <router-view/>
            </div>
        </div>
    </div>
</template>

<script>
import router from "@/router/index"
export default {
    name: "Main",
    data() {
        return {
            selected: [sessionStorage.getItem("tabSelectedMain")],
            width:256+'px',
            height:document.body.offsetHeight+'px',
            collapsed: false,
            avatar:'',
            username:'',
            routes:[],
        }
    },
    mounted() {
        let res = localStorage.getItem('siteUserInfo')
        this.avatar = JSON.parse(res).avatar
        this.username = JSON.parse(res).username
        this.routes = router.options.routes[router.options.routes.length-1].children
    },

    beforeDestroy() {
    },
    methods: {
        logout(){
            localStorage.clear('siteUserInfo')
            this.$router.push('/')
        },
        toggleCollapsed() {
            this.collapsed = !this.collapsed;
        },
        temp(){
            this.$router.push('/')
            sessionStorage.setItem("tabSelected",'index')
        },
        menuClick(menuPath){
            const {key} = menuPath
            this.$store.commit('setSelectedMain',[key])
            switch (key) {
                case 'banner':
                    this.$router.push('bannerOption')
                    break;
                case '项目案例':
                    this.$router.push('caseOption')
                    break;
                case '产品服务':
                    this.$router.push('productOption')
                    break;
                case '新闻资讯':
                    this.$router.push('newsOption')
                    break;
                case '解决方案':
                    this.$router.push('solveOption')
                    break;
                case '关于我们':
                    this.$router.push('aboutOption')
                    break;
            }
        },
    },
}
</script>
<style scoped lang="scss">
.logout-text{
    transition: all .4s ease-in-out;
    cursor: pointer;
    &:hover{
        color: #409EFF;
    }
}
::v-deep.ant-avatar{
    box-shadow: 0 0 1px 1px #d7d7d7;
   img{
       object-fit: contain !important;

   }
}
.main-container{
    display: flex;
    min-height:919px;
    .logo{
        padding: 20px;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            margin-right: 10px;
            width: 200px;
        }
        span{
            width: 180px;
            color: aliceblue;
            font-size: 18px;
            line-height: normal;
        }
    }
    .right-side{
        width: 100%;
        .header{
            background: #FFFFFF;
            display: flex;
            justify-content: space-between;

            width: 100%;
            height: 60px;
            align-items: center;
            .shink{
                margin-left: 20px;
            }
            .right-menu{
                display: flex;
                align-items: center;
                float: right;
                right: 0;
                top: 0;
                span{
                    margin: 10px;
                }
                .username{
                    color: #626262;
                    user-select: none;
                    cursor: pointer;
                }
            }
        }
        .view{
        }
    }

}

</style>
