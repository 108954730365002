import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import notFound from "@/views/404.vue"
import Main from "@/appViews/main.vue"
import { loadingBar } from 'iview'
import 'iview/dist/styles/iview.css';
import {message} from 'ant-design-vue'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/product',
      name: 'product',
      component: () => import('../views/product.vue')
    },
    {
      path: '/solve',
      name: 'solve',
      component: () => import( '../views/solve.vue')
    },
    {
        path: '/case',
        name: 'case',
        component: () => import( '../views/case.vue')
    },
    {
        path: '/caseDetail',
        name: 'caseDetail',
        component: () => import( '../views/caseDetail.vue')
    },
    {
        path: '/news',
        name: 'news',
        component: () => import( '../views/news.vue')
    },
    {
        path: '/newsDetail',
        name: 'newsDetail',
        component: () => import( '../views/newsDetail.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import( '../views/about.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import( '../views/login.vue')
    },
    {
        path: '/404',
        name: 'Page404',
        component: notFound
    },
    {
        path: '*',
        redirect: '/404'
    },
]

const operatorRoutes = [
    {
        path:'/main',
        name:'main',
        component:Main,
        children:[
            {
                path: 'bannerOption',
                name: 'banner',
                component: () => import( '../appViews/bannerOption.vue'),
                meta:{access:true}
            },
            {
                path: 'caseOption',
                name: '项目案例',
                component: () => import( '../appViews/caseOption.vue'),
                meta:{access:true}

            },
            {
                path: 'productOption',
                name: '产品服务',
                component: () => import( '../appViews/productOption.vue'),
                meta:{access:true}
            },
            {
                path: 'newsOption',
                name: '新闻资讯',
                component: () => import( '../appViews/newsOption.vue'),
                meta:{access:true}
            },
            {
                path: 'solveOption',
                name: '解决方案',
                component: () => import( '../appViews/solveOption.vue'),
                meta:{access:true}
            },
            {
                path: 'aboutOption',
                name: '关于我们',
                component: () => import( '../appViews/aboutOption.vue'),
                meta:{access:true}
            },
        ]
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes:[...routes,...operatorRoutes]
})

router.beforeEach((to, from, next) => {
    let userInfo = JSON.parse(localStorage.getItem('siteUserInfo'))
        if(to.meta.access){
            if(userInfo){
                loadingBar.start();
                next();
            }else{
                message.error('尚未登录')
                next('/login')
            }
        }else{
            loadingBar.start();
            next();
        }


});

router.afterEach(route => {
    loadingBar.finish();
});

export default router
