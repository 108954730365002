<template>
    <div class="banner-container" :style="{height}">
        <a-skeleton active v-for="(item,i) in 2" :key="i" v-if="loading"/>
        <a-carousel v-if="!loading"  :autoplay="true" dotPosition="right" effect="fade"  :autoplaySpeed="speed">
            <div class="item" :style="{height:height}" v-for="(item,i) in data" :key="i">
                <img class="banner" :src="item.img.url" @click="handleClick(item)"/>
                <span class="introduce-title">{{item.name}}</span>
                <span class="introduce">{{item.introduce}}</span>
            </div>
        </a-carousel>
    </div>
</template>
<script>
export default {
    name:'banner',
    props:['data','height','speed'],
    data() {
        return {
            loading:false,
        }
    },
    mounted() {
        this.loading = true
        setTimeout(()=>{
            this.loading = false
        },200)
    },
    methods: {
        handleClick(item){
            console.log('当前轮播图跳转类型：',item.model)
            console.log('跳转地址：',item.target)
            if(item.model == 1){
                //TODO 站内跳转，目前未知是直接后台配置跳站内某个链接，还是需要这里根据文章的参数查询后直接跳转文章
                this.$store.commit('setSelected','news')
                this.$router.push('/newsDetail?id='+item.target)
            }else if(item.model == 2){
                window.open(item.target)
            }else{
                return
            }
        },
    },
}
</script>
<style scoped lang="scss">
.banner-container{
    overflow: hidden;
}
$width:1080px;
//::v-deep.ant-carousel .slick-vertical .slick-slide{
//    border: unset !important;
//    height: $width;
//}
.item{
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: $width;
    background-color: #ececec;
    position: relative;
    .introduce-title{
        position: absolute;
        left:14%;
        top:100px;
        font-size: 58px;
        color: #FFFFFF;
    }
    .introduce{
        width: 1400px;
        height: 200px;
        font-family:"Microsoft YaHei";
        letter-spacing: 3px;
        position: absolute;
        left:14%;
        top:200px;
        font-size: 18px;
        color: #FFFFFF;
    }
    .banner{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}
</style>
