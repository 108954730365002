export default {
     baseURL(){
        let baseURL
        if (process.env.NODE_ENV === 'development') {
            // return  baseURL = '/api'
            return  baseURL = 'https://www.cpzsyun.com/api/plant/'

        } else if (process.env.NODE_ENV === 'production') {
            return  baseURL = 'https://www.cpzsyun.com/api/plant/'
        }
    },
    imgBaseURL(){
         return `https://www.cpzsyun.com/file/`
},
}
