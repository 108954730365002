<template>
    <a-layout-header class="header">
        <div class="logo">
            <img :src="logo"/>
<!--            <span>原链科技有限公司</span>-->
        </div>
        <a-menu class="menu" mode="horizontal"  v-model="selected" style="lineHeight:64px;background-color: transparent;font-size: 18px;" :style="{color:fontColor}" @click="clickNav">
            <a-menu-item key="index">首页</a-menu-item>
            <a-menu-item key="product" @mouseenter="mouseIn">产品服务</a-menu-item>
            <a-menu-item key="solve">解决方案</a-menu-item>
            <a-menu-item key="case">项目案例</a-menu-item>
            <a-menu-item key="news">最新动态</a-menu-item>
            <a-menu-item key="about">关于我们</a-menu-item>
        </a-menu>
        <div class="right-menu">
<!--            <a-button shape="round" class="contact" @click="contact">联系我们</a-button>-->
<!--            <span class="icon">-->
<!--                <a-icon type="search" style="font-size: 24px;color: aliceblue;margin-top: 25px" :style="{color:searchColor}"></a-icon>-->
<!--            </span>-->
            <span v-if="username" @click="temp" class="username" :class="[selected[0] == 'index' || selected[0] == 'product' ?'':'user-text']">{{username}}</span>
            <router-link v-else to="/login"><span class="username" :class="[selected[0] == 'index' || selected[0] == 'product' ?'':'user-text']">登录</span></router-link>
        </div>
    </a-layout-header>
</template>

<script>
import {productType, selectProduct} from "@/api/apis";
import handleRequest from "@/utils/handleRequest";
import {mapState} from "vuex";

export default {
    name: 'Y-header',
      props: ['fontColor','searchColor'],
    data() {
        return {
            logo:require('@/assets/logo-black.png'),
            selected:[sessionStorage.getItem("tabSelected")],
            username:JSON.parse(localStorage.getItem('siteUserInfo'))?.username || '',
        }
    },
    mounted() {
        if(this.$route.path == '/' || this.$route.path == '/product'){
            this.logo = require('@/assets/logo@2x.png')
        }
    },
    beforeDestroy() {
    },
    methods: {
        contact(){
            this.$router.push({
                path:'/about',
            })
            this.$store.commit('setSelected',['about'])
            this.$store.commit('setToPhone',true)
        },
        mouseIn(){
            let currentRoute = '/product'
            if(this.$route.path == currentRoute){
                this.$store.commit('setProductShow',true)
            }
        },
        temp(){
            this.jump('/main/bannerOption')
            sessionStorage.setItem("tabSelectedMain",'banner')
        },
        jump(path){
            this.$router.push(path)
        },
        async clickNav({vm,key,keyPath}) {
            this.$store.commit('setSelected',[key])
            const {jump} = this
            switch (key){
                case 'index':
                    jump('/')
                    break;
                case 'product':
                    const data = handleRequest(await productType())
                    this.$nextTick((()=>{
                        this.$store.commit('setProductShow',false)
                        if(data.length){
                            jump({
                                path:'/product',
                            })
                        }
                    }))
                    break;
                case 'solve':
                    jump('solve')
                    break;
                case 'case':
                    jump('case')
                    break;
                case 'news':
                    jump('news')
                    break;
                case 'about':
                    jump('about')
                    break;
            }
        }
    },
}
</script>

<style scoped lang="scss">
.user-text{
    color: rgba(31, 31, 31, 0.84) !important;
}
.contact{
    border: 0;
    color: #FFFFFF !important;
    letter-spacing: 3px;
    width: 144px;
    height: 38px;
    font-size: 16px;
    opacity: .8;
    background: linear-gradient(90deg, #00C8FF 0%, #3769FE 100%) !important;
    &:hover{
        opacity: 1;
        background: linear-gradient(90deg, #00C8FF 0%, #3769FE 100%);
        color: #FFFFFF;
    }
}
.menu{
    border-bottom: 0;
}
a{
    width: 200px !important;
}
@media screen and (max-width: 1500px) {
    .logo{
        float: left;
        width: 335px;
        //height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            margin-top: .5rem;
            //margin-right: .5rem;
            width: 150px;
            height: 38px;
        }
        span{
            color: aliceblue;
            font-size: 24px;
            line-height: normal;
            display: none;
        }
    }
    .right-menu{
        display: flex;
        align-items: center;
        float: right;
        margin-top: 0 !important;
        right: 0;
        top: 0;
        margin:10px 10px;
        margin-bottom: 0;
        a{
            width: 200px !important;

        }
        .username{
            color: aliceblue;
            user-select: none;
            cursor: pointer;
        }
        .icon{
            margin: 0 !important;
        }
    }
    button{
        display: none;
    }
}
::v-deep.ant-menu-item-selected{
    background-color: transparent !important;
}
.header{
    position: absolute;
    z-index: 100;
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: space-around;
    .logo{
        float: left;
        width: 335px;
        //height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            margin-top: .5rem;
            //margin-right: .5rem;
            width: 150px;
            height: 38px;
        }
        span{
            color: aliceblue;
            font-size: 24px;
            line-height: normal;
        }
    }
    .right-menu{
        display: flex;
        align-items: center;
        float: right;
        right: 0;
        top: 0;
        span{
            margin:0px 21px;
            margin-bottom: 0;
        }
        .username{
            color: aliceblue;
            user-select: none;
            cursor: pointer;
        }
    }
    ::v-deep .ant-menu-item{
        margin: 0 40px;
    }
}

</style>
